import { QUERY_KEY } from '@constants/queries';

import { GET } from '@lib/axios';

import { useQuery } from '@tanstack/react-query';
import { InternalNotification } from '@utils/typesPages/types';

const fetchInternalNotification = async () => {
  const { data } = await GET<InternalNotification[]>(
    '/api/internal-notifications'
  );

  return data;
};

export const useFetchInternalNotifications = ({
  enabled,
}: {
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: [QUERY_KEY.FETCH_INTERNAL_NOTIFICATIONS],
    queryFn: () => fetchInternalNotification(),
    refetchInterval: 120000,
    enabled,
  });
};
