import {
  DEFAULT_WHITESPACE_PUBLICITY_RATIO,
  IS_RADIO_ACTIVE_CONFIG,
  WHITESPACE_PUBLICITY_RATIO_CONFIG,
} from '@constants/configurations';
import { Configuration } from '@prisma/client';

export const getWhiteSpacePublicity = (configurations?: Configuration[]) => {
  return parseInt(
    configurations?.find(
      (configuration) =>
        configuration.name === WHITESPACE_PUBLICITY_RATIO_CONFIG
    )?.value ?? DEFAULT_WHITESPACE_PUBLICITY_RATIO
  );
};

export const getRadioIsActive = (configurations?: Configuration[]) => {
  return parseInt(
    configurations?.find(
      (configuration) => configuration.name === IS_RADIO_ACTIVE_CONFIG
    )?.value ?? '0'
  );
};

export const getSocialLinks = (configurations?: Configuration[]) => {
  const linkNames = [
    'facebook_url',
    'instagram_url',
    'twitter_url',
    'tiktok_url',
    'linkedin_url',
    'youtube_url',
    'spotify_url',
    'apple_store_url',
    'google_play_url',
  ];

  const links = linkNames.reduce(
    (acc, name) => {
      const foundLink = configurations?.find(
        (configuration) => configuration.name === name
      )?.value;

      acc[`${name.replace('_url', '')}Url`] = foundLink;
      return acc;
    },
    {} as Record<string, string | undefined | null>
  );

  return links;
};
