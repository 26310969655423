import { FC } from 'react';
import { Box, Flex, Grid, Stack, Text } from '@mantine/core';
import { useRouter } from 'next/router';
import { useFooterStyles } from './styles';
import { FooterData, FooterProps } from './types';
import { Logo } from '@atoms/Logo';
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandSpotify,
  IconBrandTiktok,
  IconBrandYoutube,
  IconBrandX,
  IconBrandLinkedin,
} from '@tabler/icons-react';
import GooglePlayStoreImage from '@images/google-play.png';
import AppleStoreImage from '@images/apple-store.png';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useFetchPublicConfiguration } from '@hooks/queries/useFetchPublicConfiguration';
import { getSocialLinks } from '@helpers/configurations';

export const Footer: FC<FooterProps> = ({ onOpenSidebar }) => {
  const { classes } = useFooterStyles();
  const router = useRouter();
  const { t } = useTranslation('common');

  const { data: configurations } = useFetchPublicConfiguration();

  const socialLinks = getSocialLinks(configurations);

  const footerData: FooterData = [
    {
      name: t('common.footer.about.name'),
      title: t('common.footer.about.name'),
      links: [
        {
          label: t('common.footer.about.label.editorial'),
          link: '/editorial',
        },
        {
          label: t('common.footer.about.label.contacts'),
          link: '/contactos',
        },
        {
          label: t('common.footer.about.label.privacy'),
          link: '/privacidade',
        },
        {
          label: t('common.footer.about.label.terms'),
          link: '/termos',
        },
      ],
    },
    {
      name: t('common.footer.findOut.name'),
      title: t('common.footer.findOut.name'),
      links: [
        {
          label: t('common.footer.findOut.label.authors'),
          link: '/autores',
        },
        {
          label: t('common.footer.findOut.label.categories'),
        },
      ],
    },
    {
      name: t('common.footer.app.name'),
      title: t('common.footer.app.title'),
      links: [
        {
          image: {
            alt: t('common.footer.image.google.alt'),
            src: GooglePlayStoreImage.src,
          },
          externalLink: socialLinks.google_playUrl ?? '',
        },
        {
          image: {
            alt: t('common.footer.image.apple.alt'),
            src: AppleStoreImage.src,
          },
          externalLink: socialLinks.apple_storeUrl ?? '',
        },
      ],
    },
  ];

  const footerSections = footerData.map((section, index) => {
    const links = section.links.map((link, index) =>
      link.image ? (
        <Flex
          pos="relative"
          w={120}
          h={40}
          key={index}
          className={classes.imageLink}
        >
          <Image
            fill
            onClick={(event) => {
              event.preventDefault();
              if (link.externalLink) {
                window.open(new URL(link.externalLink));
              }
            }}
            sizes="auto"
            alt={link.image.alt}
            src={link.image.src}
          />
        </Flex>
      ) : (
        <Flex key={index}>
          <Text
            className={classes.link}
            variant="footerText"
            component="span"
            onClick={(event) => {
              event.preventDefault();
              if (!link.link) {
                onOpenSidebar();
                return;
              }
              router.push(link.link ?? '');
            }}
          >
            {link.label}
          </Text>
        </Flex>
      )
    );

    return (
      <Grid.Col span={12} sm={3} key={section.title}>
        <Text variant="footerTitle" mb={4}>
          {index === section.links.length
            ? section.title
            : section.title?.toLocaleUpperCase()}
        </Text>
        {links}
      </Grid.Col>
    );
  });

  return (
    <Box mt="auto">
      <footer className={classes.footer}>
        <Grid>
          <Grid.Col span={12} sm={3}>
            <Stack>
              <div className={classes.logo}>
                <Logo href="/" />
                <Text variant="footerText" className={classes.description}>
                  {t('common.footer.address')}
                </Text>
              </div>
              <Text variant="footerTitle" component="span">
                {t('common.footer.follow').toLocaleUpperCase()}
              </Text>
              <Flex gap={12}>
                {socialLinks.facebookUrl && (
                  <IconBrandFacebook
                    onClick={() =>
                      window.open(new URL(socialLinks.facebookUrl ?? ''))
                    }
                    className={classes.socialIcon}
                  />
                )}
                {socialLinks.instagramUrl && (
                  <IconBrandInstagram
                    onClick={() =>
                      window.open(new URL(socialLinks.instagramUrl ?? ''))
                    }
                    className={classes.socialIcon}
                  />
                )}
                {socialLinks.twitterUrl && (
                  <IconBrandX
                    onClick={() =>
                      window.open(new URL(socialLinks.twitterUrl ?? ''))
                    }
                    className={classes.socialIcon}
                  />
                )}
                {socialLinks.tiktokUrl && (
                  <IconBrandTiktok
                    onClick={() =>
                      window.open(new URL(socialLinks.tiktokUrl ?? ''))
                    }
                    className={classes.socialIcon}
                  />
                )}
                {socialLinks.youtubeUrl && (
                  <IconBrandYoutube
                    onClick={() =>
                      window.open(new URL(socialLinks.youtubeUrl ?? ''))
                    }
                    className={classes.socialIcon}
                  />
                )}
                {socialLinks.spotifyUrl && (
                  <IconBrandSpotify
                    onClick={() =>
                      window.open(new URL(socialLinks.spotifyUrl ?? ''))
                    }
                    className={classes.socialIcon}
                  />
                )}
                {socialLinks.linkedinUrl && (
                  <IconBrandLinkedin
                    onClick={() =>
                      window.open(new URL(socialLinks.linkedinUrl as string))
                    }
                    className={classes.socialIcon}
                  />
                )}
              </Flex>
            </Stack>
          </Grid.Col>
          {footerSections}
        </Grid>
      </footer>
    </Box>
  );
};
