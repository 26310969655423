import { ROUTES } from '@constants/routes';
import { hasRoutePermission } from '@utils/routes';
import { signOut, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { FC, ReactNode } from 'react';

export const ProtectedRoutes: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const router = useRouter();

  const { status, data: session } = useSession();

  if (status === 'unauthenticated') {
    router.push('/auth/login');
    return null;
  }

  if (session?.user?.deleted_at) {
    signOut({
      callbackUrl: '/auth/login',
    });
    return null;
  }

  if (status === 'authenticated') {
    const roleEndpoints = ROUTES[session?.user?.role as keyof typeof ROUTES];

    if (!hasRoutePermission(roleEndpoints, router.route)) {
      router.push(ROUTES[session.user?.role as keyof typeof ROUTES][0]);
      return null;
    }
  }
  return <div>{children}</div>;
};
