import { ROUTES } from '@constants/routes';
import { IncomingHttpHeaders, IncomingMessage } from 'http';
import { NextApiRequest } from 'next';
import { getToken } from 'next-auth/jwt';

export const isUserAllowed = (route: string, role?: string) => {
  const keyRole = role as keyof typeof ROUTES;

  return ROUTES[keyRole].includes(route);
};

export const hasMappedHeaders = (
  headers: Headers | IncomingMessage['headers']
): headers is Headers => {
  return headers instanceof Headers;
};

export const isAuthenticated = async <Request>({
  req,
  referer,
}: {
  req: Request;
  referer?: string | null;
}) => {
  const token = await getToken({ req: req as NextApiRequest });

  const session = {
    user: {
      id: token?.id,
      authorId: token?.authorId,
      name: token?.name,
      email: token?.email,
      role: token?.userRole,
      deleted_at: token?.deleted_at,
    },
  };

  if (
    !session?.user?.id ||
    session?.user.deleted_at ||
    (referer && !referer?.includes('/admin'))
  ) {
    return;
  }

  return session;
};

export const getSessionFromHeaders = (headers: IncomingHttpHeaders) => {
  return {
    user: {
      id: headers.sessionid as string | undefined,
      role: headers.sessionrole as string | undefined,
    },
  };
};
