export const QUERY_KEY = {
  FETCH_AUTHORS: 'useFetchAuthors',
  FETCH_NEWS_HIGHLIGHTED: 'useFetchNewsHighlighted',
  FETCH_AUTHORS_INFINITE: 'useFetchInfiniteAuthors',
  FETCH_CATEGORIES: 'useFetchCategories',
  FETCH_CATEGORY_BY_SLUG: 'useFetchCategoryBySlug',
  FETCH_CATEGORY_BY_SLUG_BACKOFFICE: 'useFetchCategoryBySlugBackoffice',
  FETCH_EPISODES_ALL_PUBLIC: 'useFetchAllEpisodes',
  FETCH_EPISODES_BACKOFFICE: 'useFetchEpisodesBackoffice',
  FETCH_EPISODE_BY_SLUG_BACKOFFICE: 'useFetchEpisodeBySlugBackoffice',
  FETCH_EPISODES_PUBLIC: 'useFetchEpisodes',
  FETCH_INTERNAL_NOTIFICATIONS: 'useFetchInternalNotifications',
  FETCH_NEWS: 'useFetchNews',
  FETCH_NEWS_BACKOFFICE: 'useFetchNewsBackoffice',
  FETCH_NEWS_BY_CATEGORY: 'useFetchNewsByCategory',
  FETCH_NEWS_BY_SLUG: 'useFetchNewsBySlug',
  FETCH_NEWS_BY_SLUG_BACKOFFICE: 'useFetchNewsSlugBackoffice',
  FETCH_NEWS_PUBLIC: 'useFetchInfiniteNews',
  FETCH_NEWS_PUBLIC_BY_CATEGORY: 'useFetchInfiniteNews',
  FETCH_LIVE_BLOGS_BACKOFFICE: 'useFetchLiveBlogsBackoffice',
  FETCH_LIVE_BLOGS: 'useFetchLiveBlogs',
  FETCH_LIVE_POSTS_BACKOFFICE: 'useFetchLivePostsBackoffice',
  FETCH_LIVE_POSTS: 'useFetchLivePosts',
  FETCH_LIVE_BLOG_BY_SLUG: 'useFetchLiveBlogBySlug',
  FETCH_PODCASTS_BACKOFFICE: 'useFetchPodcastsBackoffice',
  FETCH_PODCASTS: 'useFetchPodcasts',
  FETCH_INFINITE_PODCASTS: 'useFetchInfinitePodcasts',
  FETCH_PODCASTS_BY_SLUG: 'useFetchPodcastBySlug',
  FETCH_PODCASTS_BY_SLUG_BACKOFFICE: 'useFetchPodcastBySlugBackoffice',
  FETCH_PUBLIC_SETTING: 'useFetchPublicSetting',
  FETCH_SEO: 'useFetchSEO',
  FETCH_PUBLICITY_BACKOFFICE: 'useFetchPublicityBackoffice',
  FETCH_PUBLICITY_BACKOFFICE_BY_ID_BACKOFFICE:
    'useFetchPublicityBackofficeByIdBackoffice',
  FETCH_PUBLICITY: 'useFetchPublicity',
  FETCH_USERS_BACKOFFICE: 'useFetchUsersBackoffice',
  FETCH_MANUAL_NOTIFICATIONS: 'useFetchManualNotifications',
  FETCH_MANUAL_NOTIFICATION_BY_ID_BACKOFFICE:
    'useFetchManualNotificationByIdBackoffice',
  FETCH_PUBLIC_CONFIGURATION: 'useFetchPublicConfiguration',
  FETCH_CONFIGURATION: 'useFetchConfiguration',
  FETCH_USER_BY_ID: 'useFetchUserById',
  FETCH_AUTHOR: 'useFetchAuthor',
  FETCH_SEARCH: 'useFetchSearch',
};
