import { FC } from 'react';
import { Switch, useMantineColorScheme, useMantineTheme } from '@mantine/core';
import { SunIcon, MoonIcon } from '@heroicons/react/24/outline';

export const SwitchColorScheme: FC = () => {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const theme = useMantineTheme();

  return (
    <Switch
      label="switch-color-scheme"
      sx={{
        '.mantine-Switch-labelWrapper': {
          display: 'none',
        },
        '.mantine-Switch-track': {
          backgroundColor: theme.colors.tertiary[0],
          borderColor: theme.colors.tertiary[0],
        },
        '.mantine-Switch-thumb': {
          backgroundColor:
            colorScheme === 'dark' ? theme.colors.white : theme.colors.dark[9],
        },
      }}
      color="gray"
      checked={colorScheme === 'dark'}
      onChange={() => toggleColorScheme()}
      size="md"
      onLabel={<MoonIcon color={theme.white} width="1rem" />}
      offLabel={<SunIcon color={theme.colors.dark[9]} width="1rem" />}
    />
  );
};
