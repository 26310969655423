import {
  DEFAULT_SEO_DESCRIPTION,
  DEFAULT_SEO_KEYWORDS,
  DEFAULT_SEO_TITLE,
  DISALLOWED_URLS,
} from '@constants/seo';
import Head from 'next/head';
import { FC } from 'react';
import { SEOPost, SEOProps } from './types';
import { useRouter } from 'next/router';

const addSchemaOrg = (path: string) => {
  return {
    __html: `{
    "@context": "https://schema.org/",
    "@type": "WebSite",
    "name": "Ria",
    "url": "${process.env.NEXT_PUBLIC_APP_URL}${path}",
    "image": "${''}",
    "description": "${DEFAULT_SEO_DESCRIPTION}",
    "publisher": {
      "@type": "Organization",
      "name": "Ria",
      "logo": "${''}"
    },
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "${process.env.NEXT_PUBLIC_APP_URL}"
    }
  }
`,
  };
};

const addSchemaOrgPost = (blog: SEOPost, path: string) => {
  return {
    __html: `{
    "@context": "https://schema.org/",
    "@type": "BlogPosting",
    "headline": "${blog.title}",
    "datePublished": "${blog.publishedAt}",
    "url": "${process.env.NEXT_PUBLIC_APP_URL}${path}",
    "image": {
      "@type": "ImageObject",
      "url": "${blog.mainImageUrl}"
    },
    "description": "${blog.description}",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "${process.env.NEXT_PUBLIC_APP_URL}${path}",
      "name": "${blog.title}"
      },
    "publisher": {
      "@type": "Organization",
      "name": "Wiseverge",
      "logo": ${LOGO_AZURE}
    }
  }
`,
  };
};

const LOGO_AZURE =
  'https://riaprodstorageaccount.blob.core.windows.net/ria-prod/orange_logo.svg';

export const SEO: FC<SEOProps> = ({
  title = DEFAULT_SEO_TITLE,
  keywords = DEFAULT_SEO_KEYWORDS,
  description = DEFAULT_SEO_DESCRIPTION,
  type = 'website',
  post,
  index = true,
  image_url = LOGO_AZURE,
}) => {
  const router = useRouter();

  const path = router.asPath.split('?')[0];

  const trimTrailingSlash = (str: string) => {
    if (str.charAt(str.length - 1) === '/') {
      return str.slice(0, -1);
    }

    return str;
  };

  return (
    <Head>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="apple-mobile-web-app-title" content={title} />
      <meta name="application-name" content={title} />
      <meta property="og:type" content={type} />
      <meta name="theme-color" content="#ffffff" />
      <meta
        property="og:url"
        content={`${process.env.NEXT_PUBLIC_APP_URL}${path}`}
      />
      <meta
        httpEquiv="Content-Security-Policy"
        content="frame-src 'self' https://open.spotify.com https://www.youtube.com;"
      />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image_url} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:alt" content={title} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:card" content="summary" />
      <meta property="twitter:site" content="@radioriapt" />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={image_url} />
      <meta property="twitter:image:alt" content={title} />
      {index &&
      process.env.NEXT_PUBLIC_APP_URL?.includes('radioria.pt') &&
      !DISALLOWED_URLS.some((url) => router.asPath.includes(url)) ? (
        <meta name="robots" content="index" />
      ) : (
        <meta name="robots" content="noindex" />
      )}
      <link
        rel="canonical"
        href={trimTrailingSlash(`${process.env.NEXT_PUBLIC_APP_URL}${path}`)}
        hrefLang="en"
      />
      <link rel="apple-touch-icon" href={LOGO_AZURE} />
      <link rel="icon" type="image/png" href={LOGO_AZURE} />
      <title>{title}</title>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={
          post ? addSchemaOrgPost(post, path) : addSchemaOrg(path)
        }
        key="schemaOrg"
      />
    </Head>
  );
};
