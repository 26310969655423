import { MUTATION_KEY } from '@constants/mutations/queries';
import { QUERY_KEY } from '@constants/queries';
import { PATCH } from '@lib/axios';

import { useMutation, useQueryClient } from '@tanstack/react-query';

const markNotificationsAsRead = async (notification_ids: string[]) => {
  const { data } = await PATCH('/api/internal-notifications/read', {
    notification_ids,
  });

  return data;
};

export const useMarkNotificationsAsRead = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [MUTATION_KEY.MARK_INTERNAL_NOTIFICATIONS_AS_READ],
    mutationFn: markNotificationsAsRead,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.FETCH_INTERNAL_NOTIFICATIONS],
      }),
  });
};
