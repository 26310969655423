import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import pt from 'dayjs/locale/pt';
import { DateValue } from '@mantine/dates';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Europe/Lisbon');
dayjs.locale(pt);
dayjs.extend(relativeTime);

export const nowUTC = (): Date => {
  return dayjs().toDate();
};

export const formatLastNews = (date: string | Date) => {
  const now = dayjs();

  const isToday = dayjs(date).isSame(now, 'day');

  if (isToday) {
    return dayjs(date).tz().format('HH:mm');
  }

  const isSameYear = dayjs(date).isSame(now, 'year');

  if (isSameYear) {
    return dayjs(date).tz().format('DD/MM');
  }

  return undefined;
};

export const formatDisplayHours = (date: string | Date) => {
  return dayjs(date).format('HH:mm');
};

export const convertDateToUTC = (date: string | Date | DateValue) => {
  return dayjs(date).toDate().toISOString();
};

export const convertDateToLocal = (date: string | Date) => {
  return dayjs(date).tz().format('DD MMM YYYY');
};

export const convertDateForInputs = (date: string | Date) => {
  return dayjs(date).toDate();
};

export const getFormattedDate = (date: string | Date) => {
  return dayjs(date).tz().format('DD MMM YYYY, HH:mm');
};

export const isBeforeStartDate = (start: string | Date, end: string | Date) => {
  const startDate = dayjs(start);
  const endDate = dayjs(end);

  return startDate.isBefore(endDate);
};

export const getTimeAgo = (date: Date | string) => {
  return dayjs().to(dayjs(date));
};

export const getStringDayMonthAndYear = (date: string | Date) => {
  return dayjs(date).tz().format('DD MMM YYYY');
};

export const isBeforeDate = (date: string | Date) => {
  return dayjs(date).isBefore(dayjs());
};
