import { QUERY_KEY } from '@constants/queries';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MUTATION_KEY } from '@constants/mutations/queries';
import { PATCH } from '@lib/axios';

const markInternalNotificationsAsUnread = async (id: string) => {
  const { data } = await PATCH(`/api/internal-notifications/${id}/unread`);

  return data;
};

export const useMarkInternalNotificationsAsUnread = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [MUTATION_KEY.UNREAD_INTERNAL_NOTIFICATION],
    mutationFn: markInternalNotificationsAsUnread,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.FETCH_INTERNAL_NOTIFICATIONS],
      }),
  });
};
