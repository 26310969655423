import { FC, useEffect, useState } from 'react';
import { Button, Center, Navbar, Text, Transition } from '@mantine/core';
import { useStyles } from './styles';
import Link from 'next/link';

import { useTranslation } from 'next-i18next';
import { SIDEBAR_BACKOFFICE_ROUTES } from '@constants/routes';
import { SidebarBackofficeProps } from './types';
import { useRouter } from 'next/router';
import { isUserAllowed } from '@utils/auth';
import { signOut, useSession } from 'next-auth/react';
import { ArrowLeftOnRectangleIcon } from '@heroicons/react/24/outline';

export const SidebarBackoffice: FC<SidebarBackofficeProps> = ({
  showNavbar,
  setNavbar,
}) => {
  const { classes, cx } = useStyles();
  const [active, setActive] = useState('Billing');
  const { t } = useTranslation('dashboard');
  const router = useRouter();

  const { data: session } = useSession();

  const onClickRoute = () => {
    if (typeof window !== 'undefined' && window) {
      if (window.innerWidth <= 766) {
        setNavbar(false);
      }
    }
  };

  useEffect(() => {
    SIDEBAR_BACKOFFICE_ROUTES.forEach((route) => {
      if (router.pathname.includes(route.href)) {
        setActive(route.label);
      }
    });
  }, [router]);

  if (!session) {
    return null;
  }

  return (
    <Transition
      mounted={showNavbar}
      transition="slide-left"
      duration={200}
      timingFunction="ease"
    >
      {() => (
        <Navbar
          height="100%"
          width={{ sm: 300 }}
          p={{ sm: 'md', base: 'xs' }}
          className={classes.navbar}
        >
          <Navbar.Section>
            {SIDEBAR_BACKOFFICE_ROUTES.map(
              (link) =>
                isUserAllowed(link.href, session?.user.role) && (
                  <Link
                    className={cx(classes.link, {
                      [classes.linkActive]: link.label === active,
                    })}
                    href={link.href}
                    key={link.label}
                    onClick={() => {
                      onClickRoute();
                    }}
                  >
                    <link.icon className={classes.linkIcon} />
                    <Text size="md">{t(link.label)}</Text>
                  </Link>
                )
            )}
          </Navbar.Section>
          <Navbar.Section className={classes.footer}>
            <Center>
              <Button
                rightIcon={<ArrowLeftOnRectangleIcon />}
                variant="subtleDanger"
                onClick={() => {
                  signOut({
                    callbackUrl: '/auth/login',
                  });
                }}
              >
                <Text size="md">{t('dashboard.logout')}</Text>
              </Button>
            </Center>
          </Navbar.Section>
        </Navbar>
      )}
    </Transition>
  );
};
