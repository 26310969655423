import { createStyles, getStylesRef } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  sections: {
    '&:hover': {
      color:
        theme.colorScheme !== 'dark'
          ? theme.colors.dark[4]
          : theme.colors.dark[1],
    },
  },
  link: {
    ...theme.fn.focusStyles(),
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[1]
        : theme.colors.gray[7],
    fontWeight: 500,
    fontSize: 16,
    '&:hover': {
      color: theme.colors.primary[0],
      [`& .${getStylesRef('icon')}`]: {
        color: theme.colors.primary[0],
      },
    },
  },
  linkActive: {
    color: theme.colors.primary[0],
  },
}));
