import { AppShell, Container } from '@mantine/core';
import { FC, useEffect, useState } from 'react';
import { LayoutProps } from './types';
import { SidebarBackoffice } from '@organisms/backoffice/Sidebar';
import { HeaderBackoffice } from '@molecules/backoffice/Header';
import { useFetchInternalNotifications } from '@hooks/queries/useFetchInternalNotifications';
import { useSession } from 'next-auth/react';
import { SEO } from '@organisms/SEO';

export const DashboardAppShellLayout: FC<LayoutProps> = ({ children }) => {
  const [showNavbar, setNavbar] = useState(false);

  const { data: session } = useSession();

  useEffect(() => {
    if (typeof window !== 'undefined' && window && window.innerWidth > 766) {
      setNavbar(true);
    }
  }, []);

  const { data: internalNotifications } = useFetchInternalNotifications({
    enabled: !!session?.user.id,
  });

  return (
    <AppShell
      header={
        <HeaderBackoffice
          setNavbar={setNavbar}
          showNavbar={showNavbar}
          notifications={internalNotifications}
        />
      }
      navbar={
        <SidebarBackoffice showNavbar={showNavbar} setNavbar={setNavbar} />
      }
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
          overflowX: 'hidden',
        },
      })}
    >
      <Container fluid px={0}>
        <SEO index={false} />
        {children}
      </Container>
    </AppShell>
  );
};
