import type { AppProps } from 'next/app';
import {
  ColorScheme,
  ColorSchemeProvider,
  MantineProvider,
} from '@mantine/core';
import { mantineCache } from 'mantineCache';
import {
  HydrationBoundary,
  QueryClient,
  QueryClientProvider,
  keepPreviousData,
} from '@tanstack/react-query';
import { useState } from 'react';
import { appWithTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { DashboardAppShellLayout } from '@layouts/DashboardAppShell';
import { NewspaperAppShellLayout } from '@layouts/NewspaperAppShellLayout';
import { Notifications } from '@mantine/notifications';
import { themeProps } from '@utils/mantineTheme';
import { SessionProvider } from 'next-auth/react';
import { useDisclosure } from '@mantine/hooks';
import { ProtectedRoutes } from 'src/components/hoc/ProtectedRoutes';
import { useScrollRestoration } from '@hooks/useScrollRestoration';
import '@styles/editorJs.css';
import '@styles/inputs.css';
import { FONT_PROJECT } from 'public/fonts';
import { SEO } from '@organisms/SEO';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: { placeholderData: keepPreviousData, staleTime: 3600, gcTime: 0 },
  },
});

const App = ({ Component, pageProps: { session, ...pageProps } }: AppProps) => {
  const pagesWithoutSidebar = ['/auth/login', 'auth/email-sent'];
  const router = useRouter();
  useScrollRestoration(router);
  const [colorScheme, setColorScheme] = useState<ColorScheme>('dark');
  const [isSidebarOpen, { open: onOpenSidebar, close: onCloseSidebar }] =
    useDisclosure(false);

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <HydrationBoundary state={pageProps.dehydratedState}>
          <ColorSchemeProvider
            colorScheme={colorScheme}
            toggleColorScheme={toggleColorScheme}
          >
            <MantineProvider
              emotionCache={mantineCache}
              withNormalizeCSS
              withGlobalStyles
              theme={{
                colorScheme: colorScheme,
                colors: {
                  primary: ['#f03232'],
                  secondary: ['#646ef0'],
                  tertiary: ['#efeff6'],
                  dark: [
                    '#d5d7e0',
                    '#b4b6c8',
                    '#64646b',
                    '#71738d',
                    '#595a73',
                    '#93959c',
                    '#3c3c43',
                    '#28282f',
                    '#14141b',
                    '#0a0a11',
                  ],
                },
                ...themeProps,
              }}
            >
              <Notifications position="top-right" />
              {router.route.includes('admin') &&
              !router.route.includes('login') ? (
                <SessionProvider session={session}>
                  <ProtectedRoutes>
                    <DashboardAppShellLayout>
                      <Component {...pageProps} />
                    </DashboardAppShellLayout>
                  </ProtectedRoutes>
                </SessionProvider>
              ) : pagesWithoutSidebar.find((a) => router.route.includes(a)) ? (
                <main className={FONT_PROJECT.className}>
                  <SEO index={false} />
                  <Component {...pageProps} />
                </main>
              ) : (
                <NewspaperAppShellLayout
                  isSidebarOpen={isSidebarOpen}
                  onCloseSidebar={onCloseSidebar}
                  onOpenSidebar={onOpenSidebar}
                >
                  <Component {...pageProps} />
                </NewspaperAppShellLayout>
              )}
            </MantineProvider>
          </ColorSchemeProvider>
        </HydrationBoundary>
      </QueryClientProvider>
    </>
  );
};

export default appWithTranslation(App);
