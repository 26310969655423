import { createStyles } from '@mantine/styles';

export const useStyles = createStyles((theme) => ({
  dropdownMenu: {
    padding: 6,
  },
  readAll: {
    color: theme.colors.gray[5],
    textAlign: 'right',
    fontSize: 12,
    '&:hover': { textDecoration: 'underline', cursor: 'pointer' },
  },
  menuItem: {
    padding: '12px 20px 12px 0px',
  },
}));
