import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  header: {
    background:
      theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.white,
    borderBottom: `0.1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[4]
    }`,
  },
  socialIcon: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.colors.dark[5],
    width: 22,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));
