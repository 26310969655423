import { FC, useState } from 'react';
import { Avatar } from '@atoms/Avatar';
import { useMarkInternalNotificationAsRead } from '@hooks/mutations/useMarkInternalNotificationAsRead';
import { useMarkInternalNotificationsAsUnread } from '@hooks/mutations/useMarkInternalNotificationsAsUnread';
import { useMarkNotificationsAsRead } from '@hooks/mutations/useMarkNotificationsAsRead';
import {
  Divider,
  Flex,
  Menu,
  Radio,
  ScrollArea,
  Stack,
  Switch,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useStyles } from './styles';
import { renderNotificationText } from '@services/internal-notifications';
import { getTimeAgo } from '@utils/dates';
import { NotificationDropdownMenuProps } from './types';

export const NotificationDropdownMenu: FC<NotificationDropdownMenuProps> = ({
  notifications,
}) => {
  const { classes } = useStyles();

  const { t } = useTranslation('dashboard');

  const theme = useMantineTheme();

  const { mutate: markInternalNotificationAsRead } =
    useMarkInternalNotificationAsRead();
  const { mutate: markInternalNotificationsAsUnread } =
    useMarkInternalNotificationsAsUnread();
  const { mutate: markNotificationsAsRead } = useMarkNotificationsAsRead();

  const [showReadOnly, setShowReadOnly] = useState(true);

  const route = useRouter();

  const handleReadChange = (notificationId: string, type: string) => {
    if (type === 'read') {
      markInternalNotificationAsRead(notificationId);
    } else if (type === 'unread') {
      markInternalNotificationsAsUnread(notificationId);
    }
  };

  const unreadNotifications = notifications.filter((notification) =>
    showReadOnly ? !notification.read_at : true
  );

  const unreadNotificationIds = notifications
    .filter((notification) => notification.read_at === null)
    .map((notification) => notification.id);

  const notificationCards = unreadNotifications.map((notification, index) => {
    const { sender } = notification;

    const handleDropdownMenuClick = (e: { stopPropagation: () => void }) => {
      e.stopPropagation();
      handleReadChange(notification.id, 'read');
      route.push(notification.resource_url);
    };

    return (
      <Menu.Item
        onClick={handleDropdownMenuClick}
        key={index}
        className={classes.menuItem}
      >
        <Flex gap={10} justify="space-between">
          <Flex gap={16}>
            <Avatar name={sender.name} />
            <Stack spacing={0}>
              <Flex w="100%" gap={8}>
                <Text variant="caption1">
                  <Text variant="caption1Bold" component="span">
                    {sender.name}
                  </Text>
                  {` ${renderNotificationText(notification.notification_type)}`}
                </Text>
              </Flex>
              <Text variant="caption2" component="span">
                {getTimeAgo(notification.created_at)}
              </Text>
            </Stack>
          </Flex>

          <Flex gap={10} justify="end" align="baseline">
            <Radio
              size="xs"
              sx={{
                '.mantine-Radio-radio': {
                  background: theme.white,
                  '&:hover': {
                    cursor: 'pointer',
                  },
                },
                '.mantine-Radio-icon': {
                  color: theme.colors.blue[7],
                },
              }}
              value={notification.id}
              checked={!notification.read_at}
              onChange={(event) => {
                event.preventDefault();
              }}
              onClick={(event) => {
                event.stopPropagation();
                if (notification.read_at) {
                  handleReadChange(notification.id, 'unread');
                } else {
                  handleReadChange(notification.id, 'read');
                }
              }}
            />
          </Flex>
        </Flex>
      </Menu.Item>
    );
  });

  return (
    <Menu.Dropdown
      className={classes.dropdownMenu}
      maw={{ base: 250, xxs: 340, xs: 400 }}
    >
      <Stack px={12} spacing={0}>
        <Flex
          gap={8}
          direction={{ base: 'column', sm: 'row' }}
          justify="space-between"
          align="flex-start"
          py={12}
        >
          <Title variant="h4Dark">{t('notification.dropdown.title')}</Title>
          <Switch
            size="xs"
            labelPosition="left"
            label={t('notification.dropdown.onlyShowUnread')}
            checked={showReadOnly}
            onChange={() => {
              setShowReadOnly(!showReadOnly);
            }}
          />
        </Flex>
        <Divider />
        <Flex justify="space-between" my={16}>
          <Text variant="captionXsBold">
            {t('notification.dropdown.title.recent').toLocaleUpperCase()}
          </Text>
          <Text
            onClick={() => {
              markNotificationsAsRead(unreadNotificationIds);
            }}
            className={classes.readAll}
            variant="captionXsBold"
            component="span"
          >
            {t('notification.dropdown.readAll')}
          </Text>
        </Flex>
      </Stack>

      <ScrollArea scrollbarSize={8} h={400} mb={24}>
        {notificationCards}
      </ScrollArea>
    </Menu.Dropdown>
  );
};
