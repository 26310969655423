import { ActionIcon, Flex, Header } from '@mantine/core';
import { Bars3Icon } from '@heroicons/react/24/outline';
import { Logo } from '@atoms/Logo';
import { SwitchColorScheme } from '@atoms/SwitchColorScheme';
import { HeaderBackofficeProps } from './types';
import { FC } from 'react';
import { NotificationBell } from '@molecules/backoffice/NotificationBell';
import { useStyles } from './styles';

export const HeaderBackoffice: FC<HeaderBackofficeProps> = ({
  setNavbar,
  showNavbar,
  notifications,
}) => {
  const { classes, theme } = useStyles();

  return (
    <Header
      height={60}
      mb={120}
      p={{ sm: 'md', base: 'xs' }}
      className={classes.header}
    >
      <ActionIcon variant="transparent" onClick={() => setNavbar(!showNavbar)}>
        <Bars3Icon
          color={
            theme.colorScheme === 'dark' ? theme.white : theme.colors.dark[9]
          }
        />
      </ActionIcon>
      <Flex justify="center" mt={8}>
        <Logo
          href="/admin/news"
          variant={theme.colorScheme === 'dark' ? 'orange' : 'black'}
        />
      </Flex>
      <Flex align="center" gap={20}>
        <SwitchColorScheme />
        <NotificationBell notifications={notifications ?? []} />
      </Flex>
    </Header>
  );
};
