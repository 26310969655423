export const MUTATION_KEY = {
  APPROVE_EPISODE: 'useApproveEpisode',
  APPROVE_LIVE_BLOGS: 'useApproveLiveBlogs',
  APPROVE_LIVE_POSTS: 'useApproveLivePosts',
  APPROVE_NEWS: 'useApproveNews',
  APPROVE_PUBLICITY: 'useApprovePublicity',
  CREATE_AUTHOR: 'useCreateAuthor',
  CREATE_CATEGORY: 'useCreateCategory',
  CREATE_EPISODE: 'useCreateEpisode',
  CREATE_LIVE_BLOGS: 'useCreateLiveBlogs',
  CREATE_LIVE_POSTS: 'useCreateLivePosts',
  CREATE_NEWS: 'useCreateNews',
  CREATE_PODCAST: 'useCreatePodcast',
  CREATE_PUBLIC_SETTING: 'useCreatePublicSetting',
  CREATE_SEO: 'useCreateSEO',
  CREATE_PUBLICITY: 'useCreatePublicity',
  CREATE_USER: 'useCreateUser',
  DELETE_AUTHOR: 'useDeleteAuthor',
  DELETE_CATEGORY: 'useDeleteCategory',
  DELETE_EPISODE: 'useDeleteEpisode',
  DELETE_LIVE_BLOGS: 'useDeleteLiveBlogs',
  DELETE_LIVE_POSTS: 'useDeleteLivePosts',
  DELETE_NEWS: 'useDeleteNews',
  DELETE_PODCAST: 'useDeletePodcast',
  DELETE_PUBLICITY: 'useDeletePublicity',
  DELETE_USER: 'useDeleteUser',
  EDIT_AUTHOR: 'useEditAuthor',
  EDIT_CATEGORY: 'useEditCategory',
  EDIT_EPISODE: 'useEditEpisode',
  EDIT_IMAGE: 'useEditImage',
  EDIT_IMAGE_AUTHOR: 'useEditAuthorImage',
  EDIT_LIVE_BLOGS: 'useEditLiveBlogs',
  EDIT_LIVE_POSTS: 'useEditLivePosts',
  EDIT_NEWS: 'useEditNews',
  EDIT_PODCAST: 'useEditLivePodcast',
  EDIT_PUBLICITY: 'useEditPublicity',
  EDIT_PUBLIC_SETTING: 'useEditPublicSetting',
  EDIT_USER: 'useEditUser',
  MARK_INTERNAL_NOTIFICATIONS_AS_READ: 'useMarkInternalNotificationsAsRead',
  MARK_INTERNAL_NOTIFICATION_AS_READ: 'useMarkInternalNotificationAsRead',
  REJECT_EPISODE: 'useRejectEpisode',
  REJECT_LIVE_BLOGS: 'useRejectLiveBlogs',
  REJECT_LIVE_POSTS: 'useRejectLivePosts',
  REJECT_NEWS: 'useRejectNews',
  REJECT_PUBLICITY: 'useRejectPublicity',
  STOP_LIVE_BLOGS: 'useStopLiveBlogs',
  STOP_PUBLICITY: 'useStopPublicity',
  UNREAD_INTERNAL_NOTIFICATION: 'useUnreadInternalNotification',
  UPDATE_CATEGORY_ORDER: 'useUpdateCategoryOrder',
  UPDATE_NEWS_ORDER: 'useUpdateNewsOrder',
  UPLOAD_AUTHORS_IMAGE: 'useUploadAuthorsImage',
  UPLOAD_IMAGE: 'useUploadImage',
  CREATE_MANUAL_NOTIFICATION: 'useCreateManualNotification',
  EDIT_MANUAL_NOTIFICATION: 'useEditManualNotification',
  DELETE_MANUAL_NOTIFICATION: 'useDeleteManualNotification',
  CREATE_CONFIGURATION: 'useCreateConfiguration',
  UPDATE_CONFIGURATION: 'useUpdateConfiguration',
  VIEW_PUBLICITY: 'useViewPublicity',
};
