import { QUERY_KEY } from '@constants/queries';
import { useQuery } from '@tanstack/react-query';
import { GET } from '@lib/axios';
import { Configuration } from '@prisma/client';

export const fetchPublicConfiguration = async () => {
  const { data: response } = await GET<Configuration[]>('api/configurations', {
    params: { is_public: true },
  });

  return response;
};

export const useFetchPublicConfiguration = () => {
  return useQuery({
    queryKey: [QUERY_KEY.FETCH_PUBLIC_CONFIGURATION],
    queryFn: fetchPublicConfiguration,
  });
};
