import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background:
      theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.white,
  },
}));
