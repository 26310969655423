import { InternalNotificationType } from '@utils/typesPages/types';
import { i18n } from 'next-i18next';

export const renderNotificationText = (
  notificationType: InternalNotificationType
) => {
  switch (notificationType) {
    case 'APPROVED_EPISODE':
      return i18n?.t('notification.episode.approved', {
        ns: 'dashboard',
      });
    case 'APPROVED_LIVE_BLOG':
      return i18n?.t('notification.liveBlog.approved', {
        ns: 'dashboard',
      });
    case 'APPROVED_LIVE_POST':
      return i18n?.t('notification.livePost.approved', {
        ns: 'dashboard',
      });
    case 'APPROVED_NEWS':
      return i18n?.t('notification.news.approved', {
        ns: 'dashboard',
      });
    case 'APPROVED_PUBLICITY':
      return i18n?.t('notification.publicity.approved', {
        ns: 'dashboard',
      });
    case 'CREATED_EPISODE':
      return i18n?.t('notification.episode.created', {
        ns: 'dashboard',
      });
    case 'CREATED_LIVE_BLOG':
      return i18n?.t('notification.liveBlog.created', {
        ns: 'dashboard',
      });
    case 'CREATED_LIVE_POST':
      return i18n?.t('notification.livePost.created', {
        ns: 'dashboard',
      });
    case 'CREATED_NEWS':
      return i18n?.t('notification.news.created', {
        ns: 'dashboard',
      });
    case 'CREATED_PODCAST':
      return i18n?.t('notification.podcast.created', {
        ns: 'dashboard',
      });
    case 'CREATED_PUBLICITY':
      return i18n?.t('notification.publicity.created', {
        ns: 'dashboard',
      });
    case 'REJECTED_EPISODE':
      return i18n?.t('notification.episode.rejected', {
        ns: 'dashboard',
      });
    case 'REJECTED_LIVE_BLOG':
      return i18n?.t('notification.liveBlog.rejected', {
        ns: 'dashboard',
      });
    case 'REJECTED_LIVE_POST':
      return i18n?.t('notification.livePost.rejected', {
        ns: 'dashboard',
      });
    case 'REJECTED_NEWS':
      return i18n?.t('notification.news.rejected', {
        ns: 'dashboard',
      });
    case 'REJECTED_PUBLICITY':
      return i18n?.t('notification.publicity.rejected', {
        ns: 'dashboard',
      });
    case 'STOPPED_LIVE_BLOG':
      return i18n?.t('notification.liveBlog.stopped', {
        ns: 'dashboard',
      });
    case 'STOPPED_PUBLICITY':
      return i18n?.t('notification.publicity.stopped', {
        ns: 'dashboard',
      });
  }
};
