import { AppShell, Container, Flex } from '@mantine/core';
import { PageHeader } from '@molecules/frontoffice/Header';
import { FC, useMemo } from 'react';
import { LayoutProps } from './types';
import { SidebarFrontoffice } from '@organisms/frontoffice/Sidebar';
import { useRouter } from 'next/router';
import { Footer } from '@organisms/Footer';
import { TopSectionNavigation } from '@molecules/frontoffice/TopSectionsNavigation';
import { RadioPlayBar } from '@molecules/frontoffice/RadioPlayBar';
import { NEXT_TITLE_ON_AIR, TITLE_ON_AIR } from 'src/mockdata';
import { getSectionLimitConfiguration } from '@utils/configurations';
import { getRadioIsActive } from '@helpers/configurations';
import { useFetchCategories } from '@hooks/queries/useFetchCategories';
import { useFetchPublicConfiguration } from '@hooks/queries/useFetchPublicConfiguration';
import { getCategorySections, getFilteredRoutes } from '@helpers/sidebar';

export const NewspaperAppShellLayout: FC<LayoutProps> = ({
  children,
  onCloseSidebar,
  isSidebarOpen,
  onOpenSidebar,
}) => {
  const router = useRouter();

  const { data: configurations } = useFetchPublicConfiguration();

  const { data: categoriesData } = useFetchCategories({
    fetchAll: true,
  });

  const sectionLimit = getSectionLimitConfiguration(configurations ?? []);

  const displayFooter = router.pathname !== '/';

  const tabBarSections = useMemo(() => {
    const isRadioActive = getRadioIsActive(configurations) === 1;
    const filteredRoutes = getFilteredRoutes(isRadioActive, true);
    const categorySections = getCategorySections(categoriesData, sectionLimit);

    return [...filteredRoutes, ...categorySections];
  }, [categoriesData, configurations, sectionLimit]);

  return (
    <AppShell
      header={<PageHeader onOpenSidebar={onOpenSidebar} />}
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
        },
        overflowX: 'hidden',
      })}
      padding={0}
      footer={
        getRadioIsActive(configurations) === 1 ? (
          <Flex
            style={{ position: 'fixed', bottom: 0, width: '100%', zIndex: 50 }}
          >
            <RadioPlayBar
              onPlayClick={() => {
                return;
              }}
              nextTitleOnAir={TITLE_ON_AIR}
              titleOnAir={NEXT_TITLE_ON_AIR}
            />
          </Flex>
        ) : undefined
      }
    >
      <Container size="xl" h="85%">
        <SidebarFrontoffice
          categoriesData={categoriesData}
          isSidebarOpen={isSidebarOpen}
          onCloseSidebar={onCloseSidebar}
          isRadioActive={getRadioIsActive(configurations) === 1}
        />
        {tabBarSections && categoriesData && categoriesData.data.length > 0 && (
          <TopSectionNavigation sections={tabBarSections} />
        )}
        <Flex
          h="100%"
          mt={{ base: 20, lg: tabBarSections ? 40 : 20 }}
          direction="column"
        >
          {children}
          {displayFooter && <Footer onOpenSidebar={onOpenSidebar} />}
        </Flex>
      </Container>
    </AppShell>
  );
};
