import { FC, useMemo } from 'react';
import { Box, Drawer, Flex, Text, Title } from '@mantine/core';
import { useStyles } from './styles';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { SidebarFrontofficeProps } from './types';
import { useRouter } from 'next/router';
import { getCategorySections, getFilteredRoutes } from '@helpers/sidebar';

export const SidebarFrontoffice: FC<SidebarFrontofficeProps> = ({
  isSidebarOpen,
  onCloseSidebar,
  categoriesData,
  isRadioActive,
}) => {
  const { classes, cx, theme } = useStyles();
  const { t } = useTranslation('common');
  const router = useRouter();
  const currentYear = new Date().getFullYear();

  const sections = useMemo(() => {
    const filteredRoutes = getFilteredRoutes(!!isRadioActive, false);
    const categorySections = getCategorySections(categoriesData);

    return [
      {
        name: 'Início',
        label: 'navbar.begin',
        route: '/',
      },
      ...filteredRoutes,
      ...categorySections,
    ];
  }, [categoriesData, isRadioActive]);

  return (
    <Drawer
      opened={isSidebarOpen}
      onClose={onCloseSidebar}
      sx={{
        '.mantine-Paper-root': {
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.dark[9] : 'white',
        },
        '.mantine-Drawer-header': {
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.dark[9] : 'white',
        },
      }}
    >
      <Flex direction="column">
        <Box
          pb={8}
          w="full"
          sx={{
            borderBottom: '0.1px solid gray',
          }}
        >
          <Title variant="h3Orange" order={3}>
            {t('common.sections')}
          </Title>
        </Box>
        {sections.map((route) => (
          <Link
            className={cx(classes.link, {
              [classes.linkActive]: router.pathname === route.route,
            })}
            href={route.route}
            key={route.name}
            style={{ textDecoration: 'none', marginTop: 12 }}
            onClick={onCloseSidebar}
          >
            {route.label ? t(route.label) : route.name}
          </Link>
        ))}
      </Flex>
      <Flex mt={12} bottom={0}>
        <Text variant="caption1Gray" component="span">
          @{currentYear} Ria
        </Text>
      </Flex>
    </Drawer>
  );
};
