import { Box, Flex, Text } from '@mantine/core';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { useStyles } from './styles';
import { TopSectionsNavigationProps } from './types';
import { FC } from 'react';

export const TopSectionNavigation: FC<TopSectionsNavigationProps> = ({
  sections,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation('common');

  return (
    <Box display={{ base: 'none', md: 'flex' }}>
      <Flex
        mt={20}
        w="100%"
        justify="center"
        align="center"
        direction="row"
        gap="md"
      >
        {sections?.map((route) => (
          <Flex align="center" gap={4} key={route.name}>
            <Link href={route.route} style={{ textDecoration: 'none' }}>
              <Text component="span" variant="nav" className={classes.sections}>
                {route.label ? t(`${route.label}`) : route.name}
              </Text>
            </Link>
          </Flex>
        ))}
      </Flex>
    </Box>
  );
};
