import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  email: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.colors.dark[9],
    '&:hover > *': {
      color: theme.fn.darken(theme.colors.blue[7], 0.05),
    },
    cursor: 'pointer',
  },
}));
