/**
 * HTTP success code
 */
import { ErrorDTO } from '@pages/api/types';

export const HTTP_OK = 200;
export const HTTP_CREATED = 201;

/**
 * HTTP error code
 */

export const HTTP_NOT_MODIFIED_STATUS_CODE = 304;
export const HTTP_BAD_REQUEST_STATUS_CODE = 400;
export const HTTP_UNAUTHORIZED_STATUS_CODE = 401;
export const HTTP_PAYLOAD_TOO_LARGE = 613;
export const HTTP_PAYMENT_REQUIRED_STATUS_CODE = 402;
export const HTTP_FORBIDDEN_STATUS_CODE = 403;
export const HTTP_NOT_FOUND_STATUS_CODE = 404;
export const HTTP_METHOD_NOT_SUPPORTED_STATUS_CODE = 405;
export const HTTP_INTERNAL_SERVER_ERROR_STATUS_CODE = 500;

/**
 * Default HTTP error message
 * @description This constant will be returned from the API as a json response, and will be used on the frontend as key for the translation
 */

export const NotModifiedException = (message?: string): ErrorDTO => {
  return {
    status_code: HTTP_NOT_MODIFIED_STATUS_CODE,
    message: message ?? 'Already Exists',
  };
};

export const BadRequestException = (message?: string): ErrorDTO => {
  return {
    status_code: HTTP_BAD_REQUEST_STATUS_CODE,
    message: message ?? 'Bad Request',
  };
};

export const UnauthorizedException = (message?: string): ErrorDTO => {
  return {
    status_code: HTTP_UNAUTHORIZED_STATUS_CODE,
    message: message ?? 'Unauthorized',
  };
};

export const PaymentRequiredException = (message?: string): ErrorDTO => {
  return {
    status_code: HTTP_PAYMENT_REQUIRED_STATUS_CODE,
    message: message ?? 'Payment Required',
  };
};

export const ForbiddenException = (message?: string): ErrorDTO => {
  return {
    status_code: HTTP_FORBIDDEN_STATUS_CODE,
    message: message ?? 'Forbidden',
  };
};

export const NotFoundException = (message?: string): ErrorDTO => {
  return {
    status_code: HTTP_NOT_FOUND_STATUS_CODE,
    message: message ?? 'Not Found',
  };
};

export const NotSupportedException = (
  message?: string,
  method?: string
): ErrorDTO => {
  return {
    status_code: HTTP_METHOD_NOT_SUPPORTED_STATUS_CODE,
    message: message ?? `The HTTP method ${method} is not supported!`,
  };
};

export const InternalServerErrorException = (message?: string): ErrorDTO => {
  return {
    status_code: HTTP_INTERNAL_SERVER_ERROR_STATUS_CODE,
    message: message ?? 'Error',
  };
};
