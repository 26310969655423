import { NotificationsConfigurationsType } from '@utils/typesPages/types';
import { i18n } from 'next-i18next';

export const WHITESPACE_PUBLICITY_RATIO_CONFIG = 'white_space_publicity_ratio';
export const FAQS_CONFIG = 'faqs_configuration';
export const IS_RADIO_ACTIVE_CONFIG = 'is_radio_active';

export const DEFAULT_WHITESPACE_PUBLICITY_RATIO = '3';
export const DEFAULT_NOTIFICATION_TEXT_NEWS_CONFIG =
  'default_notification_text_news';
export const DEFAULT_NOTIFICATION_TEXT_LIVE_BLOG_CONFIG =
  'default_notification_text_live_blog';
export const DEFAULT_NOTIFICATION_TEXT_LIVE_POST_CONFIG =
  'default_notification_text_live_post';
export const DEFAULT_NOTIFICATION_TEXT_EPISODE_CONFIG =
  'default_notification_text_episode';
export const DEFAULT_NOTIFICATION_TITLE = 'Rádio Universitária de Aveiro';

export const NOTIFICATIONS_SETTINGS = (): {
  value: NotificationsConfigurationsType;
  label: string;
}[] => {
  return [
    {
      value: DEFAULT_NOTIFICATION_TEXT_NEWS_CONFIG,
      label: i18n?.t('settings.news', { ns: 'settings' }) ?? 'News',
    },
    {
      value: DEFAULT_NOTIFICATION_TEXT_LIVE_BLOG_CONFIG,
      label: i18n?.t('settings.liveBlog', { ns: 'settings' }) ?? 'LiveBlog',
    },
    {
      value: DEFAULT_NOTIFICATION_TEXT_LIVE_POST_CONFIG,
      label: i18n?.t('settings.livePost', { ns: 'settings' }) ?? 'LivePost',
    },
    {
      value: DEFAULT_NOTIFICATION_TEXT_EPISODE_CONFIG,
      label: i18n?.t('settings.episode', { ns: 'settings' }) ?? 'Episode',
    },
  ];
};

export const MULTER_FILE_SIZE = 4048576;
