import { QUERY_KEY } from '@constants/queries';

import { useQuery } from '@tanstack/react-query';
import { GET } from '@lib/axios';
import { ApiListResponse, Category } from '@utils/typesPages/types';

export const fetchCategories = async (params: {
  name?: string;
  page?: number;
  limit?: number;
  fetchAll?: boolean;
  fetchNewsSlug?: boolean;
}) => {
  const { data: response } = await GET<ApiListResponse<Category[]>>(
    '/api/categories',
    { params }
  );

  return response;
};

export const useFetchCategories = (params: {
  name?: string;
  page?: number;
  limit?: number;
  fetchAll?: boolean;
  fetchNewsSlug?: boolean;
}) => {
  return useQuery({
    queryKey: [QUERY_KEY.FETCH_CATEGORIES, params],
    queryFn: () => fetchCategories(params),
    staleTime: 0,
  });
};
