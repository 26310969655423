export const HTTP_BAD_REQUEST_MESSAGE = 'bad_request_error';
export const HTTP_UNAUTHORIZED_MESSAGE = 'unauthorized_error';
export const HTTP_FORBIDDEN_MESSAGE = 'forbidden_error';
export const HTTP_NOT_FOUND_MESSAGE = 'not_found_error';
export const HTTP_INTERNAL_SERVER_ERROR_MESSAGE = 'internal_server_error';

export enum STATUS_CODE {
  HTTP_OK = 200,
  HTTP_CREATED = 201,
  HTTP_BAD_REQUEST = 400,
  HTTP_UNAUTHORIZED = 401,
  HTTP_FORBIDDEN = 403,
  HTTP_NOT_FOUND = 404,
  HTTP_METHOD_NOT_SUPPORTED = 405,
  HTTP_INTERNAL_SERVER_ERROR = 500,
  HTTP_PAYMENT_REQUIRED = 402,
}

export const CATEGORY_ERROR_NAME = 'Category name is already in use.';
export const CATEGORY_ERROR_SLUG = 'Category slug is already in use.';
export const EMAIL_ERROR = 'Email is already in use.';
export const AUTHOR_ERROR_SLUG = 'Author slug is already in use.';
export const IMAGE_ERROR_SIZE = 'Invalid image size';
export const NEWS_ERROR_SLUG = 'News slug is already in use.';
export const LIVE_BLOG_ERROR_SLUG = 'Live blog slug is already in use.';
export const PODCAST_ERROR_SLUG = 'Podcast slug is already in use.';
export const POST_ERROR_SLUG = 'Post slug is already in use.';
