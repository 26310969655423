import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  sections: {
    '&:hover': {
      cursor: 'pointer',
      color: theme.colors.primary[0],
    },
  },
}));
