import { Configuration } from '@prisma/client';
import { NotificationsConfigurationsType } from '@utils/typesPages/types';

export const DEFAULT_SECTION_LIMIT = 7;

export const handleGetNotificationConfiguration = (
  configurations: Configuration[],
  type: NotificationsConfigurationsType
) => {
  const configuration = configurations.find(
    (configuration) => configuration.name === type
  );

  return {
    id: configuration?.id ?? '',
    type: type,
    body: configuration?.value ?? '',
  };
};

export const getSectionLimitConfiguration = (
  configurations: Configuration[]
) => {
  const configuration = configurations.find(
    (configuration) => configuration.name === 'section_limit'
  );

  if (!configuration || !configuration.value) {
    return;
  }

  return parseInt(configuration?.value);
};

export const getSectionLimitBackofficeConfiguration = (
  configurations: Configuration[]
) => {
  const configuration = configurations.find(
    (configuration) => configuration.name === 'section_limit'
  );

  if (!configuration || !configuration.value) {
    return;
  }

  return {
    id: configuration.id,
    body: configuration.value,
  };
};
