import {
  ROUTES_FRONTEND_WITH_RADIO,
  ROUTES_FRONTEND_WITHOUT_RADIO,
} from '@constants/routes';
import { DEFAULT_SECTION_LIMIT } from '@utils/configurations';
import { ApiListResponse, Category } from '@utils/typesPages/types';

export const getFilteredRoutes = (
  isRadioActive: boolean,
  isSidebar: boolean
) => {
  const routes = isRadioActive
    ? ROUTES_FRONTEND_WITH_RADIO
    : ROUTES_FRONTEND_WITHOUT_RADIO;

  if (!isSidebar) {
    return routes;
  }

  return routes.filter(
    (route) => !['Authors', 'Editorial', 'Contacts'].includes(route.name)
  );
};

export const getCategorySections = (
  categoriesData: ApiListResponse<Category[]> | undefined,
  sectionLimit?: number
) => {
  const limit = sectionLimit ? sectionLimit : DEFAULT_SECTION_LIMIT;

  return (
    categoriesData?.data?.slice(0, limit).map((category) => ({
      name: category.name,
      label: undefined,
      route: `/noticias/${category.slug}`,
    })) ?? []
  );
};
