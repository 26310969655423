import { QUERY_KEY } from '@constants/queries';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MUTATION_KEY } from '@constants/mutations/queries';
import { PATCH } from '@lib/axios';

const markInternalNotificationAsRead = async (id: string) => {
  const { data } = await PATCH(`/api/internal-notifications/${id}/read`);

  return data;
};

export const useMarkInternalNotificationAsRead = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [MUTATION_KEY.MARK_INTERNAL_NOTIFICATIONS_AS_READ],
    mutationFn: markInternalNotificationAsRead,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.FETCH_INTERNAL_NOTIFICATIONS],
      }),
  });
};
