import { FC, useState } from 'react';
import { ActionIcon, Box, Flex, Grid, rem, Slider, Text } from '@mantine/core';
import { IconVolume } from '@tabler/icons-react';
import { useTranslation } from 'next-i18next';
import { Logo } from '@atoms/Logo';
import { RadioPlayBarProps } from './types';
import { useStyles } from './styles';
import { PauseIcon, PlayIcon } from '@heroicons/react/24/solid';

export const RadioPlayBar: FC<RadioPlayBarProps> = ({
  titleOnAir,
  nextTitleOnAir,
}) => {
  const { t } = useTranslation('common');

  const [volume, setVolume] = useState(40);
  const [isPaused, setIsPaused] = useState(true);

  const onPlayClick = () => {
    setIsPaused((pervState) => !pervState);
  };

  const { classes, theme } = useStyles();

  return (
    <Grid
      px={16}
      m={0}
      w="100%"
      justify="space-between"
      style={{ backgroundColor: theme.colors.dark[9] }}
      align="center"
    >
      <Grid.Col
        span={2}
        md={1}
        style={{
          background: theme.colors.dark[7],
          borderRadius: `0 ${rem(4)} ${rem(4)} 0`,
        }}
        px={{ base: 4, md: 16 }}
      >
        <Flex
          align="center"
          gap={8}
          style={{
            placeContent: 'center',
          }}
        >
          <ActionIcon
            id="play-button"
            aria-label="play-button"
            variant="transparent"
            className={classes.playIcon}
          >
            {isPaused ? (
              <PlayIcon width={32} color="white" onClick={onPlayClick} />
            ) : (
              <PauseIcon width={32} color="white" onClick={onPlayClick} />
            )}
          </ActionIcon>
          <Box display={{ base: 'none', lg: 'flex' }}>
            <Flex align="center">
              <Text color="white">
                {t('common.radio.listen').toLocaleUpperCase()}
              </Text>
            </Flex>
          </Box>
        </Flex>
      </Grid.Col>
      <Grid.Col
        md={1}
        style={{ justifyContent: 'center' }}
        display={{ base: 'none', md: 'flex' }}
      >
        <Box display={{ base: 'none', md: 'flex' }}>
          <Flex align="center">
            <Logo href="/" />
          </Flex>
        </Box>
      </Grid.Col>
      <Grid.Col span={10} md={8}>
        <Flex gap={16} align="baseline">
          <Text variant="caption1Red">
            {t('common.radio.onAir').toLocaleUpperCase()}
          </Text>
          {titleOnAir && (
            <Text variant="caption1BoldWhite" truncate component="span">
              {titleOnAir.toLocaleUpperCase()}
            </Text>
          )}
          <Box display={{ base: 'none', md: 'flex' }}>
            <Flex gap={8}>
              <Text variant="caption2Gray">
                {t('common.radio.playNext').toLocaleUpperCase()}
              </Text>
              <Text variant="caption2Gray" truncate>
                {nextTitleOnAir?.toLocaleUpperCase()}
              </Text>
            </Flex>
          </Box>
        </Flex>
      </Grid.Col>
      <Grid.Col display={{ base: 'none', md: 'grid' }} span={2}>
        <Flex align="center" gap={8} justify="end" px={{ base: 4, md: 24 }}>
          <Slider
            thumbLabel="thumb-slider"
            value={volume}
            onChange={setVolume}
            sx={{
              '.mantine-Slider-bar': {
                backgroundColor: theme.colors.primary[0],
              },
              '.mantine-Slider-thumb ': {
                borderColor: theme.colors.primary[0],
              },
              '.mantine-1mtkbx3 ': {
                backgroundColor: theme.white,
              },
              '.mantine-3umvqe ': {
                backgroundColor: theme.white,
              },
            }}
            w={80}
          />
          <IconVolume color="white" width={32} />
        </Flex>
      </Grid.Col>
    </Grid>
  );
};
