import { IMAGE_ERROR_SIZE } from '@constants/http';
import { HTTP_PAYLOAD_TOO_LARGE } from '@constants/http/status';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

const headers: Readonly<Record<string, string | boolean>> = {
  Accept: '*/*',
  'X-Requested-With': 'XMLHttpRequest',
};

export const http = axios.create({
  baseURL: process.env.NEXT_PUBLIC_APP_URL,
  headers: headers,
});

http.interceptors.response.use(
  (response) => {
    return response;
  },
  async (
    error: AxiosError<{
      error?: string;
    }>
  ) => {
    if (error.response?.data && error.status === HTTP_PAYLOAD_TOO_LARGE) {
      error.response.data.error = IMAGE_ERROR_SIZE;
    }

    throw error;
  }
);

export const GET = <T = unknown, R = AxiosResponse<T>>(
  url: string,
  config?: AxiosRequestConfig
): Promise<R> => {
  return http.get<T, R>(url, config);
};

export const POST = <T = unknown, R = AxiosResponse<T>, D = unknown>(
  url: string,
  data?: D,
  config?: AxiosRequestConfig
) => {
  return http.post<T, R, D>(url, data, config);
};

export const PUT = <T = unknown, R = AxiosResponse<T>>(
  url: string,
  data?: T,
  config?: AxiosRequestConfig
): Promise<R> => {
  return http.put<T, R>(url, data, config);
};

export const PATCH = <T = unknown, R = AxiosResponse<T>, D = unknown>(
  url: string,
  data?: D,
  config?: AxiosRequestConfig
) => {
  return http.patch<T, R, D>(url, data, config);
};

export const DELETE = <T = unknown, R = AxiosResponse<T>>(
  url: string,
  config?: AxiosRequestConfig
): Promise<R> => {
  return http.delete<T, R>(url, config);
};
