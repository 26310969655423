import { BellAlertIcon } from '@heroicons/react/24/outline';
import { ActionIcon, Indicator, Menu, useMantineTheme } from '@mantine/core';
import { InternalNotification } from '@utils/typesPages/types';
import { FC } from 'react';
import { NotificationDropdownMenu } from '@molecules/backoffice/NotificationDropDown';

export type NotificationBellProps = {
  notifications: InternalNotification[];
};

export const NotificationBell: FC<NotificationBellProps> = ({
  notifications,
}) => {
  const theme = useMantineTheme();

  const numberOfNotifications = notifications.filter(
    (notification) => notification.read_at === null
  ).length;

  return (
    <Indicator
      disabled={!notifications || numberOfNotifications === 0}
      color={theme.colors.red[6]}
      label={numberOfNotifications}
      size={16}
      radius="sm"
    >
      <Menu offset={6} position="bottom-end">
        <Menu.Target>
          <ActionIcon radius="md" variant="transparent">
            <BellAlertIcon
              color={theme.colorScheme === 'dark' ? 'white' : 'black'}
            />
          </ActionIcon>
        </Menu.Target>
        <NotificationDropdownMenu notifications={notifications} />
      </Menu>
    </Indicator>
  );
};
