import { createStyles, getStylesRef, rem } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  navbar: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    justifyContent: 'space-between',
    zIndex: 50,
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[9] : 'white',
  },
  footer: {
    paddingTop: theme.spacing.md,
    marginTop: theme.spacing.md,
    marginBottom: 60,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,
  },
  link: {
    ...theme.fn.focusStyles(),
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    fontSize: theme.fontSizes.sm,
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[1]
        : theme.colors.gray[7],
    padding: `${theme.spacing.xs} ${theme.spacing.xs}`,
    borderRadius: theme.radius.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: '#fca5a5',
      color: theme.white,
      [`& .${getStylesRef('icon')}`]: {
        color: theme.white,
      },
    },
  },
  linkIcon: {
    ref: getStylesRef('icon'),
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[3]
        : theme.colors.gray[6],
    marginRight: theme.spacing.sm,
    width: '24px',
  },
  linkActive: {
    '&:hover': {
      backgroundColor: theme.colors.primary[0],
      color: theme.white,
      [`& .${getStylesRef('icon')}`]: {
        color: theme.white,
      },
    },
    backgroundColor: theme.colors.primary[0],
    color: theme.white,
    [`& .${getStylesRef('icon')}`]: {
      color: theme.white,
    },
  },
  logoutlink: {
    ...theme.fn.focusStyles(),
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    fontSize: theme.fontSizes.sm,
    color: theme.colors.red[5],
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
      color: theme.colors.red[7],

      [`& .${getStylesRef('icon')}`]: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      },
    },
  },
}));
