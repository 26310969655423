import { Logo } from '@atoms/Logo';
import { SwitchColorScheme } from '@atoms/SwitchColorScheme';
import { Bars3Icon } from '@heroicons/react/24/solid';
import {
  ActionIcon,
  Container,
  Flex,
  Grid,
  Group,
  Header,
  Menu,
  TextInput,
  Title,
} from '@mantine/core';
import { PageHeaderProps } from './types';
import { FC, useState } from 'react';
import { useStyles } from './styles';
import { useRouter } from 'next/router';
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandSpotify,
  IconBrandTiktok,
  IconBrandX,
  IconBrandLinkedin,
  IconBrandYoutube,
  IconSearch,
} from '@tabler/icons-react';
import { useForm } from '@mantine/form';
import { useTranslation } from 'next-i18next';
import { useFetchPublicConfiguration } from '@hooks/queries/useFetchPublicConfiguration';
import { getSocialLinks } from '@helpers/configurations';

export const PageHeader: FC<PageHeaderProps> = ({ onOpenSidebar }) => {
  const { classes, theme } = useStyles();
  const { t } = useTranslation('common');
  const router = useRouter();

  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const form = useForm<{ searchQuery: string }>({
    initialValues: {
      searchQuery: '',
    },
  });

  const { data: configurations } = useFetchPublicConfiguration();

  const socialLinks = getSocialLinks(configurations);

  return (
    <Header
      height={55}
      className={classes.header}
      sx={{
        '.mantine-2a0u86': {
          paddingRight: 0,
        },
        '.mantine-q8rzt6': {
          paddingRight: 0,
        },
      }}
      w="100%"
    >
      <Container size="xl" pos="static">
        <Grid columns={8} h="100%" w="100%" align="center">
          <Grid.Col span={3} md={3}>
            <Group>
              <ActionIcon
                id="menu-button"
                aria-label="menu-button"
                onClick={onOpenSidebar}
                ml={-2}
                variant="transparent"
              >
                <Bars3Icon
                  color={
                    theme.colorScheme === 'dark'
                      ? theme.white
                      : theme.colors.dark[9]
                  }
                  width={32}
                />
              </ActionIcon>
              <Menu
                opened={isMenuOpened}
                position="bottom-start"
                onChange={setIsMenuOpened}
              >
                <Menu.Target>
                  <ActionIcon
                    variant="transparent"
                    id="search-button"
                    aria-label="search-button"
                  >
                    <IconSearch
                      color={
                        theme.colorScheme === 'dark'
                          ? theme.white
                          : theme.colors.dark[9]
                      }
                      width={32}
                    />
                  </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                  <Flex p={8} miw={300}>
                    <TextInput
                      w="100%"
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          router.push({
                            pathname: '/pesquisa',
                            query: { searchQuery: form.values.searchQuery },
                          });

                          form.reset();
                          setIsMenuOpened(false);
                        }
                      }}
                      type="text"
                      label={t('common.header.searchInput.placeholder')}
                      placeholder={t('common.header.searchInput.title')}
                      {...form.getInputProps('searchQuery')}
                    />
                  </Flex>
                </Menu.Dropdown>
              </Menu>
              <Flex
                gap={12}
                ml={4}
                sx={(theme) => ({
                  display: 'none',

                  [theme.fn.largerThan('xl')]: {
                    display: 'flex',
                  },
                })}
              >
                {socialLinks.facebookUrl && (
                  <IconBrandFacebook
                    onClick={() =>
                      window.open(new URL(socialLinks.facebookUrl ?? ''))
                    }
                    className={classes.socialIcon}
                  />
                )}
                {socialLinks.instagramUrl && (
                  <IconBrandInstagram
                    onClick={() =>
                      window.open(new URL(socialLinks.instagramUrl ?? ''))
                    }
                    className={classes.socialIcon}
                  />
                )}
                {socialLinks.twitterUrl && (
                  <IconBrandX
                    onClick={() =>
                      window.open(new URL(socialLinks.twitterUrl ?? ''))
                    }
                    className={classes.socialIcon}
                  />
                )}
                {socialLinks.tiktokUrl && (
                  <IconBrandTiktok
                    onClick={() =>
                      window.open(new URL(socialLinks.tiktokUrl ?? ''))
                    }
                    className={classes.socialIcon}
                  />
                )}
                {socialLinks.youtubeUrl && (
                  <IconBrandYoutube
                    onClick={() =>
                      window.open(new URL(socialLinks.youtubeUrl ?? ''))
                    }
                    className={classes.socialIcon}
                  />
                )}
                {socialLinks.spotifyUrl && (
                  <IconBrandSpotify
                    onClick={() =>
                      window.open(new URL(socialLinks.spotifyUrl ?? ''))
                    }
                    className={classes.socialIcon}
                  />
                )}
                {socialLinks.linkedinUrl && (
                  <IconBrandLinkedin
                    onClick={() =>
                      window.open(new URL(socialLinks.linkedinUrl as string))
                    }
                    className={classes.socialIcon}
                  />
                )}
              </Flex>
            </Group>
          </Grid.Col>
          <Grid.Col span={2} md={2} m="auto">
            <Flex justify="center" mt={6}>
              <Logo
                href="/"
                variant={theme.colorScheme === 'dark' ? 'orange' : 'black'}
              />
            </Flex>
          </Grid.Col>
          <Grid.Col span={3} md={3}>
            <Flex justify="end" align="center" gap="md">
              <Title
                size={14}
                fw={300}
                lh={2}
                color={
                  theme.colorScheme === 'dark'
                    ? theme.colors.primary[0]
                    : theme.colors.dark[9]
                }
                display={{ base: 'none', xl: 'block' }}
              >
                RÁDIO{' '}
                <span style={{ fontWeight: 600 }}>UNIVERSITÁRIA DE AVEIRO</span>
              </Title>
              <SwitchColorScheme />
            </Flex>
          </Grid.Col>
        </Grid>
      </Container>
    </Header>
  );
};
